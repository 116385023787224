import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './AdminEmployeeAttendance.scss';

function AdminEmployeeAttendance() {
  const [attendances, setAttendances] = useState([]);
  const [timeFrame, setTimeFrame] = useState('Today');
  const [loading, setLoading] = useState(false);
  const WEBSITE_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const [selectedName, setSelectedName] = useState(null); 
  const pdfRef = useRef();

  useEffect(() => {
    setLoading(true);
    fetchAttendances(timeFrame);
  }, [timeFrame]);

  const fetchAttendances = (selectedTimeFrame) => {
    let endpoint = '';
    switch (selectedTimeFrame) {
      case 'Today':
        endpoint = '/attendance/get-attendances';
        break;
      case 'Week':
        endpoint = '/attendance/get-weekly-attendances';
        break;
      case 'Month':
        endpoint = '/attendance/get-monthly-attendances';
        break;
      default:
        break;
    }

    fetch(`${WEBSITE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setAttendances(data.attendances || []);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNameChange = (event) => {
    setSelectedName(event.target.value);
  };

  const getUniqueNames = () => {
    return [...new Set(attendances.map(attendance => attendance.userId?.name || 'N/A'))];
  };

  const filteredAttendances = attendances.filter(attendance =>
    selectedName ? attendance.userId?.name === selectedName : true
  );

  const handleTimeFrameChange = (selectedFrame) => {
    setTimeFrame(selectedFrame);
  };

  const isValidCheckInDevice = (deviceInfo) => {
    const validDeviceString = "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Mobile Safari/537.36";
    return deviceInfo === validDeviceString;
  };

  const isValidCheckOutDevice = (deviceInfo) => {
    const validCheckOutDeviceString = "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Mobile Safari/537.36";
    return deviceInfo === validCheckOutDeviceString;
  };

  const exportDataToExcel = () => {
    const data = attendances.map(attendance => ({
      Date: attendance.date ? new Date(attendance.date).toLocaleDateString('en-GB') : 'N/A',
      'First Name': attendance.userId?.name || 'N/A',
      'Last Name': attendance.userId?.lastname || 'N/A',
      Username: attendance.userId?.username || 'N/A',
      'Check-In': attendance.checkIn ? new Date(attendance.checkIn).toLocaleString('en-GB') : 'N/A',
      'Check-Out': attendance.checkOut ? new Date(attendance.checkOut).toLocaleString('en-GB') : 'N/A',
      'Check-In Device': attendance.checkIn !== null ? (isValidCheckInDevice(attendance.checkInDevice) ? 'Valid' : 'Invalid') : 'N/A',
      'Check-Out Device': attendance.checkOut !== null ? (isValidCheckOutDevice(attendance.checkOutDevice) ? 'Valid' : 'Invalid') : 'N/A',
      'Total Hours': attendance.totalHours || 'N/A',
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendances');
    XLSX.writeFile(wb, `Attendances_${timeFrame}.xlsx`);
  };

  const exportDataToPDF = () => {
    try {
      const data = attendances.map((attendance) => ({
        Date: attendance.date ? new Date(attendance.date).toLocaleDateString('en-GB') : 'N/A',
        'First Name': attendance.userId?.name || 'N/A',
        'Last Name': attendance.userId?.lastname || 'N/A',
        Username: attendance.userId?.username || 'N/A',
        'Check-In': attendance.checkIn ? new Date(attendance.checkIn).toLocaleString('en-GB') : 'N/A',
        'Check-Out': attendance.checkOut ? new Date(attendance.checkOut).toLocaleString('en-GB') : 'N/A',
        'Check-In Device': attendance.checkIn !== null ? (isValidCheckInDevice(attendance.checkInDevice) ? 'Valid' : 'Invalid') : 'N/A',
        'Check-Out Device': attendance.checkOut !== null ? (isValidCheckOutDevice(attendance.checkOutDevice) ? 'Valid' : 'Invalid') : 'N/A',
        'Total Hours': attendance.totalHours || 'N/A',
      }));

      const columns = data.length > 0 ? generateColumns(data) : [];

      const pdf = new jsPDF();
      pdf.autoTable({
        head: [columns.map(col => col.header)],
        body: data.map(row => columns.map(col => row[col.dataKey])),
      });

      pdf.save(`Attendances_${timeFrame}.pdf`);
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };

  const generateColumns = (data) => {
    if (data.length === 0) {
      return [];
    }

    const keys = Object.keys(data[0]);

    return keys.map((key) => ({
      header: key,
      dataKey: key,
    }));
  };

  return (
    <div className='p-3 p-sm-12 m-3 m-sm-10'>
      <h1>Attendances for {timeFrame}</h1>
      <div className="justify-center timeframe-buttons d-flex gap-3">
        <Button className='text-white' onClick={() => handleTimeFrameChange('Today')}>Today</Button>
        <Button className='text-white' onClick={() => handleTimeFrameChange('Week')}>Week</Button>
        <Button className='text-white' onClick={() => handleTimeFrameChange('Month')}>Month</Button>
        <select className="custom-dropdown" value={selectedName || ''} onChange={handleNameChange}>
          <option value="" disabled hidden>NAME</option>
          {getUniqueNames().map((name, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
          <CircularProgress size={80} thickness={5} style={{ color: '#004953' }} />
        </div>
      ) : attendances.length > 0 ? (
        <>
          <Button variant="contained" className='float-right mt-5 m-2' color="primary" onClick={exportDataToExcel}>
            Excel
          </Button>
          <Button variant="contained" className='float-right mt-5 m-2' color="primary" onClick={exportDataToPDF}>
            PDF
          </Button>
          <TableContainer className='p-3 p-sm-12 m-3 m-sm-10' component={Paper}>
            <Table aria-label="Employee Today Attendances Table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Check-In</TableCell>
                  <TableCell>Check-Out</TableCell>
                  <TableCell className='col-2'>Check-In Device</TableCell>
                  <TableCell className='col-2'>Check-Out Device</TableCell>
                  <TableCell>Total Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAttendances.map(attendance => (
                  <TableRow key={attendance._id}>
                    <TableCell>{attendance.date ? new Date(attendance.date).toLocaleDateString('en-GB') : 'N/A'}</TableCell>
                    <TableCell>{attendance.userId?.name || 'N/A'}</TableCell>
                    <TableCell>{attendance.userId?.lastname || 'N/A'}</TableCell>
                    <TableCell>{attendance.userId?.username || 'N/A'}</TableCell>
                    <TableCell>
                      {attendance.checkIn ? new Date(attendance.checkIn).toLocaleString('en-GB') : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {attendance.checkOut ? new Date(attendance.checkOut).toLocaleString('en-GB') : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {attendance.checkIn !== null ? (
                        isValidCheckInDevice(attendance.checkInDevice) ? (
                          <CheckCircleOutlineIcon style={{ color: 'green' }} />
                        ) : (
                          <CancelOutlinedIcon style={{ color: 'red' }} />
                        )
                      ) : <HourglassBottomOutlinedIcon />}
                    </TableCell>
                    <TableCell>
                      {attendance.checkOut !== null ? (
                        isValidCheckOutDevice(attendance.checkOutDevice) ? (
                          <CheckCircleOutlineIcon style={{ color: 'green' }} />
                        ) : (
                          <CancelOutlinedIcon style={{ color: 'red' }} />
                        )
                      ) : <HourglassBottomOutlinedIcon />}
                    </TableCell>
                    <TableCell>{attendance.totalHours || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'none' }}>
            <div ref={pdfRef}>
              {/* Dummy element for PDF generation */}
            </div>
          </div>
        </>
      ) : (
        <p>No attendances found</p>
      )}
    </div>
  );
}

export default AdminEmployeeAttendance;
