import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './employeeListing.scss';
import { isLoggedIn } from '../../Auth/auth.service';
import { deleteUser, fetchUsers } from '../adminService';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

const EmployeeListing = () => {
  const [users, setUsers] = useState([]);
  const isAuthenticated = isLoggedIn();

  const handleDeleteUser = async (userId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this user!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
      });

      if (result.isConfirmed) {
        await deleteUser(userId, isAuthenticated);
        fetchUsersAndUpdateList();
        Swal.fire('Deleted!', 'The user has been deleted.', 'success');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  const fetchUsersAndUpdateList = async () => {
    try {
      const userList = await fetchUsers(isAuthenticated);
      setUsers(userList);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsersAndUpdateList();
  }, []);


  return (
    <>
      <div className='m-5 float-right'>
        <Button className='register-employee' color="inherit" component={Link} to="/admin/register">
          + Register Employee
        </Button>
      </div>

      <div className='p-3 p-sm-12 m-3 m-sm-10'>
        <h2>Employee List</h2>

        <TableContainer component={Paper}>
          <Table aria-label="Employee List Table">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Username</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.lastname}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      className='delete-button'
                      onClick={() => handleDeleteUser(user._id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default EmployeeListing;
