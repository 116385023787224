import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import './header.scss';
import { isAdmin, logout } from '../../Auth/auth.service';
import logo from '../../assets/logo.jpg'
function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isAuthenticated = localStorage.getItem('token') !== null;
  const isAdminUser = isAdmin();
  const isMobile = useMediaQuery('(max-width:600px)');

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div className="root">
      <AppBar style={{ background: '#00E7DD' }} position="static">
        <Toolbar>
          <Typography variant="h6" className="title" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <img
              src={logo}
              alt="Aorta Pharm Logo"
              style={{ height: '40px', marginRight: '10px', cursor: 'pointer', borderRadius:'10px', border:'1px solid white' }}
            />
          </Link>

          </Typography>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          {isMobile ? (
            <div>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <div
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className='d-grid p-4 gap-4 mt-5'
                >
                  {isAdminUser && (
                    <>
                      <Button color="inherit" component={Link} to="/">
                        Attendance
                      </Button>
                      <Button color="inherit" component={Link} to="/admin/">
                        Employee
                      </Button>
                    </>

                  )}
                  <Button color="inherit" component={Link} onClick={logout}>
                    Logout
                  </Button>
                </div>
              </Drawer>
            </div>
          ) : (
            <div>
              {isAuthenticated ? (
                <>

                  {isAdminUser && (
                    <>
                      <Button color="inherit" component={Link} to="/">
                        Attendance
                      </Button>
                      <Button color="inherit" component={Link} to="/admin/">
                        Employee
                      </Button>
                    </>

                  )}
                  <Button color="inherit" component={Link} onClick={logout}>
                    Logout
                  </Button>
                </>
              ) : null}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
