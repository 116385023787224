import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';

const PORT = process.env.REACT_APP_BACKEND_PORT;
const WEBSITE_URL = process.env.REACT_APP_BACKEND_URL;


async function register(firstName, lastName, username, password) {
  const data = {
    name: firstName,
    lastname: lastName,
    username: username.toLowerCase(),
    password: password
  };

  const headers = {
    Authorization: `${isLoggedIn()}`,
  };

  return axios.post(`${WEBSITE_URL}/user/register`, data, { headers });

}

async function login(username, password, deviceInfo) {
  try {
    const response = await axios.post(`${WEBSITE_URL}/user/login`, {
      username: username.toLowerCase(),
      password,
      deviceInfo
    });
    if (response.status === 200) {
      const token = response.data.data;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `${token}`;
      return true;
    } else {
      Swal.fire('Login Failed!', 'Please check your username and password and try again.', 'error');
      console.error(response.data.message);
      return false;
    }
  } catch (error) {
    Swal.fire('Login Failed!', 'Please check your username and password and try again.', 'error');
    console.error(error.message);
    return false;
  }
}

function logout() {
  localStorage.removeItem('token');
  window.location.href = '/login';

}

function isLoggedIn() {
  return localStorage.getItem('token');
}

function isAdmin() {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.role === 'admin';
    } catch (error) {
      console.error('Error decoding token:', error);
      return false;
    }
  }
  return false;
}
export { register, login, logout, isLoggedIn, isAdmin };
