import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { login } from '../auth.service';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import './login.scss';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const deviceInfo = navigator.userAgent;
    login(username, password, deviceInfo)
      .then((response) => {
  
        if (response === true) {
          setIsCameraOpen(true);
  
          setTimeout(() => {
            setIsCameraOpen(false);
          }, 3000);
          setTimeout(() => {
            window.location.href='/'
          }, 3100);
        } else {
        }
      })
      .catch(error => {
      });
  };
  

  const handleTakePhoto = (dataUri) => {
    setIsCameraOpen(false);
  };

  return (
    <>
    {isCameraOpen && (
      <Camera className='z-10' onTakePhoto={(dataUri) => handleTakePhoto(dataUri)} />
    )}
    <div className='sign-up-form'>
      <form onSubmit={handleSubmit}>
        <div className='mt-5 grid'>
          <h2>Login</h2>
          <TextField
            label="Username"
            variant="outlined"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className='textField'
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='textField'
          />
          <Button variant="contained" type="submit">
            Login
          </Button>
        </div>
      </form>


    </div>
    </>
    
  );
};

export default Login;
