import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { register } from '../../Auth/auth.service';
import Swal from 'sweetalert2';
import './registerEmployee.scss'
const RegisterEmployee = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigation = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    register(firstName, lastName, username, password)
      .then(() => {
        Swal.fire('Registered Successfully!', 'Employee has been registered.', 'success')
          .then(() => {
            navigation('/admin/');
          });
      })
      .catch(error => {
      });
  };
  return (
    <div className='sign-up-form'>
      <form onSubmit={handleSubmit}>
        <div className='mt-5 grid'>
          <h2>Register Employee</h2>
          <TextField
            label="First Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className='textField'
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className='textField'
          />
          <TextField
            label="Username"
            variant="outlined"
            type="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className='textField'
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='textField'
          />
          <Button variant="contained" type="submit">Register</Button>
        </div>

      </form>
    </div>
  );
}

export default RegisterEmployee;