import axios from 'axios';

const WEBSITE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchUsers = async (isAuthenticated) => {
    try {
        const headers = {
            Authorization: `${isAuthenticated}`,
        };
        const response = await axios.get(`${WEBSITE_URL}/user/get-users`, { headers });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const deleteUser = async (userId, isAuthenticated) => {
    try {
        const headers = {
            Authorization: `${isAuthenticated}`,
        };
        await axios.delete(`${WEBSITE_URL}/user/users/${userId}`, { headers });
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
};
