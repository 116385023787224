import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Auth/login/login';
import RegisterEmployee from './Admin/registerEmployee/registerEmployee';
import Header from './Components/header/header';
import Attendance from './Components/attendance/attendance';
import { isLoggedIn, isAdmin } from './Auth/auth.service';
import EmployeeListing from './Admin/EmployeeListing/employeeListing';

function App() {
  const isAuth = isLoggedIn();
  const isAdminUser = isAdmin();

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {isAuth ? (
          <>
            <Route path="/" element={<Attendance />} />
            {isAdminUser ? (
              <>
                <Route path="/admin/register" element={<RegisterEmployee />} />
                <Route path="/admin/" element={<EmployeeListing />} />
              </>
            ) : (
              <Route path="/admin/*" element={<Navigate to="/" />} />
            )}

          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
