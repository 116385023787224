import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { isAdmin } from '../../Auth/auth.service';
import { Button } from '@mui/material';
import AdminEmployeeAttendance from '../../Admin/AdminEmployeeAttendance/AdminEmployeeAttendance';

const Attendance = () => {
  const token = localStorage.getItem('token');
  const WEBSITE_URL = process.env.REACT_APP_BACKEND_URL;
  const [checkIn, setCheckedIn] = useState(false);
  const [checkOut, setCheckedOut] = useState(false);
  const [checkInTime, setCheckInTime] = useState('');
  const [checkOutTime, setCheckOutTime] = useState('');
  const isAdminhere = isAdmin();
  useEffect(() => {
    const fetchAttendanceStatus = async () => {
      try {
        const response = await fetch(`${WEBSITE_URL}/attendance/status`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCheckedIn(data.attendances[0]?.checkIn !== null);
          setCheckedOut(data.attendances[0]?.checkOut !== null);
          const checkInDate = data.attendances[0]?.checkIn ? new Date(data.attendances[0]?.checkIn) : null;
          const checkOutDate = data.attendances[0]?.checkOut ? new Date(data.attendances[0]?.checkOut) : null;

          setCheckInTime(checkInDate ? checkInDate.toLocaleString('en-GB') : '');
          setCheckOutTime(checkOutDate ? checkOutDate.toLocaleString('en-GB') : '');
        } else {
          console.error('Failed to fetch attendance status');
        }
      } catch (error) {
        console.error('Error while fetching attendance status:', error);
      }
    };

    fetchAttendanceStatus();
  }, [token, WEBSITE_URL]);

  const handleCheckIn = async () => {
    const confirmCheckIn = await Swal.fire({
      title: 'Are you sure you want to check in?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Check In',
      cancelButtonText: 'Cancel',
    });

    if (confirmCheckIn.isConfirmed) {
      try {
        const response = await fetch(`${WEBSITE_URL}/attendance/check-in`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          const currentDate = new Date().toLocaleString();
          setCheckedIn(true);
          setCheckInTime(currentDate);
          Swal.fire({
            icon: 'success',
            title: 'Check-in Successful!',
            text: data.message || 'Checked in successfully!',
          });
          localStorage.removeItem('token');
          window.location.href='/'
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Check-in Failed!',
            text: data.error || 'Failed to check in!',
          });
        }
      } catch (error) {
        console.error('Error while checking in:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while checking in!',
        });
      }
    }
  };

  const handleCheckOut = async () => {
    const confirmCheckOut = await Swal.fire({
      title: 'Are you sure you want to check out?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Check Out',
      cancelButtonText: 'Cancel',
    });

    if (confirmCheckOut.isConfirmed) {
      try {
        const response = await fetch(`${WEBSITE_URL}/attendance/check-out`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          const currentDate = new Date().toLocaleString();
          setCheckedOut(true);
          setCheckOutTime(currentDate);
          Swal.fire({
            icon: 'success',
            title: 'Check-out Successful!',
            text: data.message || 'Checked out successfully!',
          });
          localStorage.removeItem('token');
          window.location.href='/'
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Check-out Failed!',
            text: data.error || 'Failed to check out!',
          });
        }
      } catch (error) {
        console.error('Error while checking out:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while checking out!',
        });
      }
    }
  };

  return (
    isAdminhere ? (
      <>
      <AdminEmployeeAttendance/>
      </>

    ) : (
      <div className="container">
        <div className="flex flex-col gap-3 mt-5">
          <div className="col-12 col-md-6">
            {!checkIn && (
              <button className="w-[250px] btn btn-primary btn-lg btn-block mb-3 py-4" onClick={handleCheckIn}>
                Check In
              </button>
            )}
            {checkIn && (
              <button disabled className="w-[250px] btn btn-primary btn-lg btn-block mb-3 py-4">
                Checked in! <br /> {checkInTime}
              </button>
            )}
          </div>
          <div className="col-12 col-md-6">
            {!checkOut && (
              <button className="w-[250px] btn btn-danger btn-lg btn-block py-4" onClick={handleCheckOut}>
                Check Out
              </button>
            )}
            {checkOut && (
              <button disabled className="w-[250px] btn btn-primary btn-lg btn-block mb-3 py-4">
                Checked out <br />
                {checkOutTime}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Attendance;
